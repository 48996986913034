import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";
import { Project, Projects } from "./types";
import { DUMMY_PROJECTS, DEFAULT_PROJECTS } from "./dummy-projects";

const defaultProjects = DEFAULT_PROJECTS.reduce((acc, project) => {
  acc[project.id] = project;
  return acc;
}, {} as Record<string, Project>);

const saveProjectsToLocalStorage = (
  projects: Record<string, Project> | null
) => {
  if (!projects) {
    projects = defaultProjects;
  }
  localStorage.setItem("projects", JSON.stringify(projects));
};

const getProjectsFromLocalStorage = (): Record<string, Project> | null => {
  const storedProjects = localStorage.getItem("projects");
  return storedProjects && storedProjects !== "{}"
    ? JSON.parse(storedProjects)
    : null;
};

const initialState: Projects = {
  projects: getProjectsFromLocalStorage() ?? defaultProjects,
  status: "idle",
  error: null,
};

export const projectsSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {
    addProject: (state, action: PayloadAction<Omit<Project, "id">>) => {
      if (!state.projects) {
        state.projects = {};
      }
      const newProjectId = uuidv4();
      state.projects[newProjectId] = { ...action.payload, id: newProjectId };
      saveProjectsToLocalStorage(state.projects);
    },
    deleteProject: (state, action: PayloadAction<string>) => {
      if (!state.projects) {
        state.projects = {};
      }
      delete state.projects[action.payload];
      saveProjectsToLocalStorage(state.projects);
    },
  },
});

export const { addProject, deleteProject } = projectsSlice.actions;
export default projectsSlice.reducer;
