import { configureStore } from "@reduxjs/toolkit";
import projectsReducer from "./projects-slice";
import selectedProjectReducer from "./selected-project-slice";
import appReducer from "./app-slice";

const store = configureStore({
  reducer: {
    app: appReducer,
    projects: projectsReducer,
    selectedProject: selectedProjectReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export default store;
