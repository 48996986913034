import React from "react";
import { Card, Typography, Space, Badge } from "antd";
import { Project } from "../../store/types";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUrlSuffix, setCreatingProject } from "../../store/app-slice";

const { Title, Text } = Typography;

interface SmallProjectCardProps {
  project: Project | null;
}

function SmallProjectCard({ project }: SmallProjectCardProps) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleOnClick = () => {
    dispatch(setCreatingProject(false));
    dispatch(setUrlSuffix("projects"));
    navigate(`/projects`);
  };

  if (!project) {
    return (
      <Card bodyStyle={{ padding: "8px" }} onClick={handleOnClick} hoverable>
        <Text>No project selected</Text>
      </Card>
    );
  }

  return (
    <Card
      style={{ borderColor: project.color, borderWidth: "2px" }}
      bordered
      bodyStyle={{ padding: "8px" }}
      onClick={handleOnClick}
      hoverable
    >
      <Space direction="vertical">
        <Title level={4} style={{ margin: "0px", lineHeight: "1" }}>
          <Badge color={project.color} text={project.name} />
        </Title>
      </Space>
    </Card>
  );
}

export default SmallProjectCard;
