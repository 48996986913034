import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import ProjectGrid from "../components/project/ProjectGrid";
import NewProjectHandler from "../components/project/NewProjectHandler";
import PageHeader from "../components/PageHeader";
import { RootState } from "../store";

function ProjectsPage() {
  const isCreatingProject = useSelector(
    (state: RootState) => state.app.creating_project
  );

  return (
    <Fragment>
      <PageHeader title="Projects" />
      {!isCreatingProject ? <ProjectGrid /> : <NewProjectHandler />}
    </Fragment>
  );
}

export default ProjectsPage;
