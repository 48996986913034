import React, { useEffect } from "react";
import {
  FormOutlined,
  SettingOutlined,
  AppstoreOutlined,
  HistoryOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { Layout, Menu } from "antd";
import { Outlet, useNavigate } from "react-router-dom";

import acceleraiLogo from "../../assets/accelerai_proto.png";
import acceleraiIcon from "../../assets/accelerai_icon.png";
import { useDispatch, useSelector } from "react-redux";
import {
  setUrlSuffix,
  setSidebarCollapsed,
  setCreatingProject,
} from "../../store/app-slice";

const { Sider, Content } = Layout;

function Sidebar() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const urlSuffix = useSelector((state: any) => state.app.url_suffix);
  // this uses redux so that the sidebar can be collapsed from anywhere and width can be taken into account
  const sidebarCollapsed = useSelector(
    (state: any) => state.app.sidebar_collapsed
  );

  const handleMenuItemClick = (key: string) => {
    if (key === "projects") {
      dispatch(setCreatingProject(false));
    }
    dispatch(setUrlSuffix(key));
    navigate(`/${key}`);
  };

  // create a useeffect that checks the current path and sets the selected key
  useEffect(() => {
    const path = window.location.pathname.split("/")[1];

    if (path === "/") {
      dispatch(setUrlSuffix(""));
    } else {
      dispatch(setUrlSuffix(path.replace("/", "")));
    }
  }, []);

  const mainMenuItems = [
    {
      key: "",
      icon: <FormOutlined />,
      label: "Chat",
    },
    {
      key: "projects",
      icon: <AppstoreOutlined />,
      label: "Projects",
    },
    {
      key: "history",
      icon: <HistoryOutlined />,
      label: "History",
    },
  ];

  const secondaryMenuItems = [
    {
      key: "info",
      icon: <InfoCircleOutlined />,
      label: "Info",
    },
    {
      key: "settings",
      icon: <SettingOutlined />,
      label: "Settings",
    },
  ];

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider
        collapsible
        collapsed={sidebarCollapsed}
        onCollapse={(value) => {
          dispatch(setSidebarCollapsed(value));
        }}
        style={{
          overflow: "auto",
          height: "100vh",
          position: "fixed",
          left: 0,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <div>
            <img
              src={sidebarCollapsed ? acceleraiIcon : acceleraiLogo}
              alt="logo"
              style={{
                height: 60,
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            />
            <Menu
              theme="dark"
              mode="inline"
              selectedKeys={[urlSuffix]}
              onClick={({ key }) => handleMenuItemClick(key)}
              items={mainMenuItems}
            ></Menu>
          </div>
          <Menu
            theme="dark"
            mode="inline"
            selectedKeys={[urlSuffix]}
            onClick={({ key }) => handleMenuItemClick(key)}
            items={secondaryMenuItems}
          ></Menu>
        </div>
      </Sider>
      <Layout style={{ marginLeft: sidebarCollapsed ? 80 : 200 }}>
        <Content
          style={{
            overflow: "initial",
            minHeight: "100%",
            position: "relative",
            overflowY: "auto",
            padding: "16px",
          }}
        >
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
}

export default Sidebar;
