import React from "react";
import { Row, Col, Layout } from "antd";
import ProjectCard from "./ProjectCard";
import { Project } from "../../store/types";
import { RootState } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedProject } from "../../store/selected-project-slice";
import { deleteProject } from "../../store/projects-slice";

function ProjectGrid() {
  const dispatch = useDispatch();
  const projects: Record<string, Project> = useSelector(
    (state: RootState) => state.projects.projects
  );

  const arrProjects = Object.values(projects);

  const handleRemove = (id: string) => {
    dispatch(deleteProject(id));
  };

  const handleSelect = (id: string) => {
    const project = projects[id];
    dispatch(setSelectedProject(project));
  };

  return (
    <Row
      gutter={[16, 16]}
      style={{
        overflowY: "auto",
        maxHeight: `calc(100vh - 150px)`,
      }}
    >
      {arrProjects.map((project, index) => (
        <Col xs={24} sm={12} md={8} lg={6} key={project.id}>
          <ProjectCard
            project={project}
            onRemove={() => handleRemove(project.id)}
            onSelect={() => handleSelect(project.id)}
            index={index}
          />
        </Col>
      ))}
    </Row>
  );
}

export default ProjectGrid;
