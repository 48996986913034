import React from "react";
import "./App.css";
import { ConfigProvider, Layout } from "antd";
import { Provider } from "react-redux";
import { RouterProvider } from "react-router-dom";

import store from "./store/index";
import { router } from "./routes/router";

function App() {
  const theme = {
    token: {
      fontFamily: "ProtoMono",
      colorPrimary: "#506385",
      // colorBgBase: "#101015",
      // colorTextBase: "#F1F0E1",
    },
  };

  return (
    <ConfigProvider theme={theme}>
      <Provider store={store}>
        <Layout style={{ height: "100vh" }}>
          <RouterProvider router={router}></RouterProvider>
        </Layout>
      </Provider>
    </ConfigProvider>
  );
}

export default App;
