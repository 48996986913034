import React, { Fragment } from "react";
import PageHeader from "../components/PageHeader";
import Chat from "../components/chat/Chat";

function ChatPage() {
  return (
    <Fragment>
      <PageHeader title="Chat"></PageHeader>
      <Chat />
    </Fragment>
  );
}

export default ChatPage;
