import React from "react";
import { Card, Button, Typography, Space, Popconfirm, Badge } from "antd";
import { motion } from "framer-motion";
import { Project } from "../../store/types";

const { Title, Paragraph } = Typography;

interface ProjectCardProps {
  project: Project;
  onRemove: () => void;
  onSelect: () => void;
  index: number;
}

function ProjectCard({ project, onRemove, onSelect, index }: ProjectCardProps) {
  const cardVariants = {
    initial: { opacity: 0, scale: 0.3 },
    animate: { opacity: 1, scale: 1 },
  };

  return (
    <motion.div
      key={project.id}
      custom={index}
      initial="initial"
      animate="animate"
      variants={cardVariants}
      transition={{ duration: 0.5, delay: index * 0.05 }}
    >
      <Card
        style={{
          borderColor: project.color,
          borderWidth: "2px",
          height: "160px",
          display: "flex",
          flexDirection: "column",
        }}
        bodyStyle={{
          padding: "16px",
          flexGrow: 1,
        }}
        bordered
        actions={[
          <Popconfirm
            title="Are you sure to delete this project?"
            onConfirm={onRemove}
            okText="Yes"
            cancelText="No"
          >
            <Button type="link" danger>
              Remove
            </Button>
          </Popconfirm>,
          <Button type="primary" onClick={onSelect}>
            Select
          </Button>,
        ]}
      >
        <Space
          direction="vertical"
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Title level={3} style={{ margin: "0px", lineHeight: "1" }}>
              <Badge color={project.color} text={project.name} />
            </Title>
            <Paragraph
              ellipsis={{ rows: 2 }}
              style={{ margin: "0px", lineHeight: "1" }}
            >
              {project.description}
            </Paragraph>
          </div>
        </Space>
      </Card>
    </motion.div>
  );
}

export default ProjectCard;
