import { createBrowserRouter } from "react-router-dom";
import Sidebar from "../components/navigation/Sidebar";
import ChatPage from "../pages/ChatPage";
import SettingsPage from "../pages/SettingsPage";
import ProjectsPage from "../pages/ProjectsPage";
import HistoryPage from "../pages/HistoryPage";
import InfoPage from "../pages/InfoPage";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Sidebar></Sidebar>,
    children: [
      {
        index: true,
        path: "/",
        element: <ChatPage></ChatPage>,
      },
      {
        path: "projects",
        element: <ProjectsPage></ProjectsPage>,
      },
      {
        path: "history",
        element: <HistoryPage></HistoryPage>,
      },
      {
        path: "info",
        element: <InfoPage></InfoPage>,
      },
      {
        path: "settings",
        element: <SettingsPage></SettingsPage>,
      },
    ],
  },
]);
