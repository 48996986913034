import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Model } from "../types/chatTypes";

type App = {
  url_suffix: string;
  api_key: string;
  sidebar_collapsed: boolean;
  default_model: Model;
  creating_project: boolean;
  send_chat_on_enter: boolean;
};

const readAppFromLocalStorage = () => {
  const savedApp = localStorage.getItem("app");
  if (savedApp) {
    const parsedApp = JSON.parse(savedApp);
    parsedApp.api_key = atob(parsedApp.api_key); // decode the API key
    return parsedApp;
  } else {
    return {
      url_suffix: "",
      api_key: "",
      sidebar_collapsed: false,
      default_model: "gpt-3.5-turbo",
      creating_project: false,
      send_chat_on_enter: false,
    };
  }
};

const initialState = readAppFromLocalStorage();

const saveAppToLocalStorage = (app: App) => {
  const appToSave = { ...app };
  appToSave.api_key = btoa(appToSave.api_key); // encode the API key
  localStorage.setItem("app", JSON.stringify(appToSave));
};

export const appSlice = createSlice({
  name: "appSlice",
  initialState,
  reducers: {
    setUrlSuffix: (state, action: PayloadAction<string>) => {
      state.url_suffix = action.payload;
      saveAppToLocalStorage(state);
    },
    setApiKey: (state, action: PayloadAction<string>) => {
      state.api_key = action.payload;
      saveAppToLocalStorage(state);
    },
    setSidebarCollapsed: (state, action: PayloadAction<boolean>) => {
      state.sidebar_collapsed = action.payload;
      saveAppToLocalStorage(state);
    },
    setDefaultModel: (state, action: PayloadAction<Model>) => {
      state.default_model = action.payload;
      saveAppToLocalStorage(state);
    },
    setCreatingProject: (state, action: PayloadAction<boolean>) => {
      state.creating_project = action.payload;
      saveAppToLocalStorage(state);
    },
    setSendChatOnEnter: (state, action: PayloadAction<boolean>) => {
      state.send_chat_on_enter = action.payload;
      saveAppToLocalStorage(state);
    },
  },
});

export const {
  setUrlSuffix,
  setApiKey,
  setSidebarCollapsed,
  setDefaultModel,
  setCreatingProject,
  setSendChatOnEnter,
} = appSlice.actions;
export default appSlice.reducer;
