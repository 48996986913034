import { Fragment } from "react";
import PageHeader from "../components/PageHeader";
import { Checkbox, Form, Input, Layout } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { setApiKey, setSendChatOnEnter } from "../store/app-slice";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
  },
};

function SettingsPage() {
  const apiKey = useSelector((state: any) => state.app.api_key);
  const sendChatOnEnter = useSelector(
    (state: any) => state.app.send_chat_on_enter
  );
  const dispatch = useDispatch();

  const handleApiKeyChange = (event: any) => {
    dispatch(setApiKey(event.target.value));
  };

  const handleSendChatOnEnterChange = (event: any) => {
    console.log(event.target.checked);
    dispatch(setSendChatOnEnter(event.target.checked));
  };

  return (
    <Fragment>
      <PageHeader title="Settings" />
      <Layout
        style={{
          display: "flex",
          justifyContent: "center",
          width: "80%",
          margin: "0 auto",
        }}
      >
        <Form layout="horizontal" {...formItemLayout}>
          <Form.Item label="API Key">
            <Input.Password
              style={{ width: "100%" }}
              allowClear
              placeholder="API Key"
              value={apiKey}
              onChange={handleApiKeyChange}
            />
          </Form.Item>
          <Form.Item label="Send Chat on Enter">
            <Checkbox
              checked={sendChatOnEnter}
              onChange={handleSendChatOnEnterChange}
            ></Checkbox>
          </Form.Item>
        </Form>
      </Layout>
    </Fragment>
  );
}

export default SettingsPage;
