import { Button, Col, Divider, Row } from "antd";
import AnimatedText from "./AnimatedText";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setUrlSuffix, setCreatingProject } from "../store/app-slice";
import SmallProjectCard from "./project/SmallProjectCard";
import { Fragment } from "react";

function PageHeader({ title }: { title: string }) {
  // grab dispatch from redux, get the dispatch function to set new project to active
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedProject = useSelector(
    (state: any) => state.selectedProject.project
  );

  return (
    <Fragment>
      <Row
        style={{
          display: "flex",
          alignItems: "center",
          height: "48px",
        }}
      >
        <Col span={8}>
          <AnimatedText text={title} type="title" />
        </Col>
        <Col
          span={8}
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <SmallProjectCard project={selectedProject}></SmallProjectCard>
        </Col>
        <Col span={8}>
          <Button
            style={{ float: "right" }}
            type="primary"
            onClick={() => {
              dispatch(setCreatingProject(true));
              dispatch(setUrlSuffix("projects"));
              navigate("/projects");
            }}
          >
            {/* <Text>New Project</Text> */}
            New Project
          </Button>
        </Col>
      </Row>
      <Divider />
    </Fragment>
  );
}

export default PageHeader;
