import React, { Fragment, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { Col, Divider, Form, Layout, Row, theme } from "antd";
import {
  CloseCircleOutlined,
  RightCircleOutlined,
  LeftCircleOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import ProjectIdentity from "./ProjectIdentity";
import ProjectSteps from "./ProjectSteps";
import ProjectSettings from "./ProjectSettings";
import { setCreatingProject } from "../../store/app-slice";
import { addProject } from "../../store/projects-slice";
import { useDispatch } from "react-redux";
import { Project } from "../../store/types";
import ProjectContextTest from "./ProjectContext";

const { useToken } = theme;

const initialProject: Project = {
  id: uuidv4(),
  color: "#506385",
  name: "",
  description: "",
  tags: [],
  settings: {
    model: "gpt-3.5-turbo",
    temperature: 1,
    max_length: 512,
    top_p: 1,
    frequency_penalty: 0,
    presence_penalty: 0,
  },
  context: [],
  creation_time: new Date().toISOString(),
  times_used: 0,
};

const initialIdentityFormValues = {
  name: "",
  description: "",
  tags: [],
  color: "#506385",
};

const initialSettingsFormValues = {
  model: "gpt-3.5-turbo",
  temperature: 1,
  max_length: 512,
  top_p: 1,
  frequency_penalty: 0,
  presence_penalty: 0,
};

const initialContextFormValues = {
  systemMessage: "",
  userAssistantMessages: [],
};

function NewProjectHandler() {
  const [identityForm] = Form.useForm();
  const [settingsForm] = Form.useForm();
  const [contextForm] = Form.useForm();
  const [currentStep, setCurrentStep] = useState(0);
  const [project, setProject] = useState<Project>(initialProject);
  const dispatch = useDispatch();
  const { token } = useToken();

  const onFormFinish = (formData: any) => {
    // Handle form submission
    if (currentStep === 0) {
      // console.log("Form submission for identity: ");
      const color =
        typeof formData.color === "string"
          ? formData.color
          : formData.color.toHexString();

      const updatedProject: Project = {
        ...project,
        name: formData.name,
        description: formData.description,
        tags: formData.tags,
        color: color,
      };

      setProject(updatedProject);
    } else if (currentStep === 1) {
      // console.log("Form submission for settings: ");
      const updatedProject: Project = {
        ...project,
        settings: {
          model: formData.model,
          temperature: formData.temperature,
          max_length: formData.max_length,
          top_p: formData.top_p,
          frequency_penalty: formData.frequency_penalty,
          presence_penalty: formData.presence_penalty,
        },
      };

      setProject(updatedProject);
    } else if (currentStep === 2) {
      // Update project context
      // console.log("Form submission for context: ");
      const systemMessageContext = {
        content: formData.systemMessage,
        role: "system",
      };

      // check if user assistant messages are undefined
      // if (formData.userAssistantMessages === undefined) {
      //   console.log("User assistant messages are undefined");
      // }

      const updatedProject: Project = {
        ...project,
        context: [systemMessageContext, ...formData.userAssistantMessages],
      };
      // console.log("Updated Project:", updatedProject);
      dispatch(addProject(updatedProject));
      setProject(updatedProject);
    } else {
      // console.log("Form submission error");
      return;
    }
  };

  const onNextClick = () => {
    // form.submit();
    if (currentStep === 0) {
      identityForm.submit();
    } else if (currentStep === 1) {
      settingsForm.submit();
    } else if (currentStep === 2) {
      contextForm.submit();
    }

    if (currentStep === 2) {
      console.log("Right button clicked");
      dispatch(setCreatingProject(false));
      return;
    }

    setCurrentStep(currentStep + 1);
  };

  const onPreviousClick = () => {
    if (currentStep === 0) {
      console.log("Left button clicked");
      dispatch(setCreatingProject(false));
    }

    setCurrentStep(currentStep - 1);
  };

  return (
    <Fragment>
      <ProjectSteps currentStep={currentStep} setStep={setCurrentStep} />
      <Divider />
      <Row
        style={{
          display: "flex",
        }}
      >
        <Col
          span={2}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
          }}
        >
          {currentStep === 0 && (
            <CloseCircleOutlined
              style={{
                marginTop: "50%",
                fontSize: "48px",
                color: token.colorError,
              }}
              onClick={onPreviousClick}
            />
          )}
          {currentStep !== 0 && (
            <LeftCircleOutlined
              style={{
                marginTop: "50%",
                fontSize: "48px",
                color: token.colorTextBase,
              }}
              onClick={onPreviousClick}
            />
          )}
        </Col>
        <Col span={20}>
          <Layout.Content
            style={{
              width: "100%",
              height: "100%",
              overflow: "hidden",
            }}
          >
            {currentStep === 0 && (
              <Form
                name="identity-form"
                form={identityForm}
                onFinish={onFormFinish}
                initialValues={initialIdentityFormValues}
                onValuesChange={(changedValues, allValues) => {
                  console.log(changedValues, allValues);
                }}
              >
                <ProjectIdentity></ProjectIdentity>
              </Form>
            )}
            {currentStep === 1 && (
              <Form
                name="settings-form"
                form={settingsForm}
                onFinish={onFormFinish}
                initialValues={initialSettingsFormValues}
                onValuesChange={(changedValues, allValues) => {
                  console.log(changedValues, allValues);
                }}
              >
                <ProjectSettings></ProjectSettings>
              </Form>
            )}
            {currentStep === 2 && (
              <Form
                name="context-form"
                layout="vertical"
                form={contextForm}
                onFinish={onFormFinish}
                initialValues={initialContextFormValues}
                onValuesChange={(changedValues, allValues) => {
                  console.log(changedValues, allValues);
                }}
              >
                <ProjectContextTest></ProjectContextTest>
              </Form>
            )}
          </Layout.Content>
        </Col>
        <Col
          span={2}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
          }}
        >
          {currentStep === 2 && (
            <CheckCircleOutlined
              style={{
                marginTop: "50%",
                fontSize: "48px",
                color: token.colorSuccess,
              }}
              onClick={onNextClick}
            />
          )}
          {currentStep !== 2 && (
            <RightCircleOutlined
              style={{
                marginTop: "50%",
                fontSize: "48px",
                color: token.colorTextBase,
              }}
              onClick={onNextClick}
            />
          )}
        </Col>
      </Row>
      {/* </Form> */}
    </Fragment>
  );
}

export default NewProjectHandler;
