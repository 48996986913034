import { AnimatePresence, motion } from "framer-motion";
import {
  Col,
  Divider,
  Row,
  Form,
  Typography,
  Input,
  Button,
  Layout,
  theme,
} from "antd";
import { MinusCircleOutlined } from "@ant-design/icons";

const { useToken } = theme;

const { Title, Text } = Typography;
const { TextArea } = Input;

function ProjectContextTest() {
  // Form is actually used as reference from parent component, do not remove
  const form = Form.useFormInstance();
  const { token } = useToken();

  return (
    <AnimatePresence>
      <motion.div
        initial={{ x: 1000, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        exit={{ x: -1000, opacity: 0, transition: { duration: 0.3 } }}
      >
        <Row gutter={24}>
          <Col span={12}>
            <Layout
              style={{
                overflowY: "scroll",
                height: `calc(100vh - 220px)`,
              }}
            >
              <div style={{ position: "sticky", top: 0, textAlign: "left" }}>
                <Title level={2}>Project Context</Title>
                <Text>
                  Configure the system input and user-assistant conversation.
                </Text>
              </div>
              <Divider />
              <Form.Item label="System Message" name="systemMessage">
                <TextArea placeholder="System input" />
              </Form.Item>
            </Layout>
          </Col>
          <Col span={12}>
            <Layout
              style={{
                overflowY: "scroll",
                height: `calc(100vh - 220px)`,
              }}
            >
              <Form.List name="userAssistantMessages">
                {/* Create a list of form items of type ChatMessage that contain content (string) and role (user or assistant) I want to be able to add items with the buttons */}
                {(fields, { add, remove }) => (
                  <>
                    {fields.map((field, index) => {
                      return (
                        <Row>
                          <Col span={20}>
                            <Form.Item
                              label={`${form.getFieldValue([
                                "userAssistantMessages",
                                index,
                                "role",
                              ])}:`}
                              name="userAssistantInput"
                              key={field.key}
                            >
                              <Form.Item
                                {...field}
                                name={[field.name, "content"]}
                              >
                                <TextArea placeholder="content" />
                              </Form.Item>
                            </Form.Item>
                          </Col>
                          <Col span={2}>
                            <MinusCircleOutlined
                              style={{
                                fontSize: "24px",
                                color: token.colorTextSecondary,
                              }}
                              onClick={() => remove(field.name)}
                            />
                          </Col>
                        </Row>
                      );
                    })}
                    <Row>
                      <Col
                        span={12}
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Button
                          type="primary"
                          onClick={() =>
                            add({ content: "", role: "assistant" })
                          }
                          style={{ width: "50%" }}
                        >
                          Add Assistant
                        </Button>
                      </Col>
                      <Col
                        span={12}
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        <Button
                          type="primary"
                          onClick={() => add({ content: "", role: "user" })}
                          style={{ width: "50%" }}
                        >
                          Add User
                        </Button>
                      </Col>
                    </Row>
                  </>
                )}
              </Form.List>
            </Layout>
          </Col>
        </Row>
      </motion.div>
    </AnimatePresence>
  );
}

export default ProjectContextTest;
