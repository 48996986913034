import { useCallback, useState } from "react";
import {
  ChatRole,
  ChatMessageToken,
  ChatMessage,
  ChatCompletionChunk,
  ChatCompletionResponseMessage,
  ChatMessageParams,
  Model,
} from "../types/chatTypes";

type RequestOptions = {
  headers: Record<string, string>;
  method: "POST";
  body: string;
  signal: AbortSignal;
};

export type OpenAIStreamingProps = {
  apiKey: string;
  model: Model;
  context: ChatMessage[];
};

const OPENAI_COMPLETIONS_URL = "https://api.openai.com/v1/chat/completions";
const MILLISECONDS_PER_SECOND = 1000;

const updateLastItem = <T>(currentItems: T[], updatedLastItem: T) => {
  const newItems = currentItems.slice(0, -1);
  newItems.push(updatedLastItem);
  return newItems;
};

const getOpenAIRequestMessage = ({
  content,
  role,
}: ChatMessage): ChatCompletionResponseMessage => ({
  content,
  role,
});

const getOpenAIRequestOptions = (
  apiKey: string,
  model: Model,
  messages: ChatMessage[],
  signal: AbortSignal
): RequestOptions => ({
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${apiKey}`,
  },
  method: "POST",
  body: JSON.stringify({
    model,
    messages: messages.map(getOpenAIRequestMessage),
    stream: true,
  }),
  signal,
});

const createChatMessage = ({
  content,
  role,
  meta,
}: ChatMessageParams): ChatMessage => ({
  content,
  role,
  timestamp: Date.now(),
  meta: {
    loading: meta?.loading || false,
    responseTime: meta?.responseTime || "",
    chunks: meta?.chunks || [],
  },
});

export const useOpenAIChatStream = ({
  model,
  apiKey,
  context = [],
}: OpenAIStreamingProps) => {
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [controller, setController] = useState<AbortController | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [initialMessages, setInitialMessages] = useState<ChatMessage[]>(
    context ?? []
  );
  const [error, setError] = useState<string | null>(null);

  const resetMessages = () => setMessages([]);

  const deleteMessage = (index: number) => {
    setMessages((prevMessages) => {
      // Filter out the message to be deleted
      return prevMessages.filter((_, i) => i !== index);
    });
  };

  const updateMessage = (index: number, content: string) => {
    setMessages((prevMessages) => {
      const updatedMessages = [...prevMessages];
      if (index >= 0 && index < updatedMessages.length) {
        updatedMessages[index] = {
          ...updatedMessages[index],
          content,
        };
      }
      return updatedMessages;
    });
  };

  const abortStream = () => {
    if (!controller) return;
    controller.abort();
    setController(null);
  };

  const closeStream = (startTimestamp: number) => {
    const endTimestamp = Date.now();
    const differenceInSeconds =
      (endTimestamp - startTimestamp) / MILLISECONDS_PER_SECOND;
    const formattedDiff = `${differenceInSeconds.toFixed(2)}s`;

    setMessages((prevMessages) => {
      const lastMessage = prevMessages.at(-1);
      if (!lastMessage) return [];

      const updatedLastMessage = {
        ...lastMessage,
        timestamp: endTimestamp,
        meta: {
          ...lastMessage.meta,
          loading: false,
          responseTime: formattedDiff,
        },
      };

      return updateLastItem(prevMessages, updatedLastMessage);
    });
  };

  const submitPrompt = useCallback(
    async (newPrompt: ChatMessageParams[]) => {
      if (isLoading || !newPrompt[0].content) return;

      setIsLoading(true);

      const startTimestamp = Date.now();

      const chatMessages: ChatMessage[] = [
        ...messages,
        ...newPrompt.map(createChatMessage),
      ];

      const newController = new AbortController();
      const signal = newController.signal;
      setController(newController);

      try {
        const response = await fetch(
          OPENAI_COMPLETIONS_URL,
          getOpenAIRequestOptions(
            apiKey,
            model,
            [...initialMessages, ...chatMessages],
            signal
          )
        );

        if (!response.ok) {
          const errorData = await response.json();

          if (response.status === 401) {
            throw new Error("Invalid API key.");
          } else {
            throw new Error(errorData.error.message);
          }
        }

        if (!response.body) return;
        const reader = response.body.getReader();
        const decoder = new TextDecoder("utf-8");

        const placeholderMessage = createChatMessage({
          content: "",
          role: "",
          meta: { loading: true },
        });
        let currentMessages = [...chatMessages, placeholderMessage];

        // log current messages with string interpolation
        // console.log(`Current messages: ${currentMessages}`);

        while (true) {
          const { done, value } = await reader.read();
          if (done) {
            closeStream(startTimestamp);
            break;
          }

          const chunk = decoder.decode(value);
          const lines = chunk.split(/\n/);

          // console.log(`Lines: ${lines}`);

          const parsedLines: ChatCompletionChunk[] = [];

          for (const line of lines) {
            const trimmedLine = line.replace(/^data:\s*/, "").trim();
            if (trimmedLine !== "" && trimmedLine !== "[DONE]") {
              try {
                const parsedLine = JSON.parse(trimmedLine);
                parsedLines.push(parsedLine);
              } catch (error) {
                console.error(`Error parsing line: ${trimmedLine}`);
                console.error(error);
              }
            }
          }

          // console.log(`Parsed lines: ${JSON.stringify(parsedLines)}`);

          for (const parsedLine of parsedLines) {
            let chunkContent: string =
              parsedLine.choices[0].delta.content ?? "";
            chunkContent = chunkContent.replace(/^`\s*/, "`");
            const chunkRole: ChatRole = parsedLine.choices[0].delta.role ?? "";

            const lastMessage = currentMessages.at(-1);
            if (!lastMessage) return;

            const updatedLastMessage = {
              content: `${lastMessage.content}${chunkContent}`,
              role: `${lastMessage.role}${chunkRole}` as ChatRole,
              timestamp: 0,
              meta: {
                ...lastMessage.meta,
                chunks: [
                  ...lastMessage.meta.chunks,
                  {
                    content: chunkContent,
                    role: chunkRole,
                    timestamp: Date.now(),
                  },
                ],
              },
            };

            currentMessages = updateLastItem(
              currentMessages,
              updatedLastMessage
            );

            setMessages(currentMessages);
          }
        }
      } catch (error) {
        if (error instanceof Error) {
          setError(error.message);
        } else {
          setError("An error occurred during chat response streaming.");
        }
      } finally {
        setController(null);
        setIsLoading(false);
      }
    },
    [apiKey, isLoading, messages, model]
  );

  return {
    messages,
    submitPrompt,
    resetMessages,
    isLoading,
    abortStream,
    updateMessage,
    deleteMessage,
    error,
  };
};
