import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Project, SelectedProjectState } from "./types";

const saveToLocalStorage = (project: Project | null) => {
  if (project) {
    localStorage.setItem("selectedProject", JSON.stringify(project));
  } else {
    localStorage.removeItem("selectedProject");
  }
};

const getFromLocalStorage = (): Project | null => {
  const storedProject = localStorage.getItem("selectedProject");
  return storedProject ? JSON.parse(storedProject) : null;
};

const initialState: SelectedProjectState = {
  project: getFromLocalStorage(),
  status: "idle",
  error: null,
};

export const selectedProjectSlice = createSlice({
  name: "selectedProject",
  initialState,
  reducers: {
    setSelectedProject: (state, action: PayloadAction<Project | null>) => {
      state.project = action.payload;
      saveToLocalStorage(action.payload);
    },
  },
});

export const { setSelectedProject } = selectedProjectSlice.actions;
export default selectedProjectSlice.reducer;
