import { motion } from "framer-motion";
import React from "react";
import { Typography } from "antd";

const { Text, Title } = Typography;

interface AnimatedTextProps {
  text: string;
  type: "text" | "title";
}

const AnimatedText: React.FC<AnimatedTextProps> = ({ text, type }) => {
  const textVariants = {
    initial: { opacity: 0 },
    animate: (delay: number) => ({
      opacity: 1,
      transition: { duration: 0.05, delay },
    }),
  };

  const characters = text.split("").map((char, index) => (
    <motion.span
      key={index}
      custom={index * 0.1}
      initial="initial"
      animate="animate"
      variants={textVariants}
    >
      {char}
    </motion.span>
  ));

  if (type === "title") {
    return (
      <Title style={{ marginBottom: "0", marginTop: "0" }}>{characters}</Title>
    );
  }

  return <Text>{characters}</Text>;
};

export default AnimatedText;
