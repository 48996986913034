import { v4 as uuidv4 } from "uuid";
import { Project } from "./types";

export const DEFAULT_PROJECTS: Project[] = [
  {
    id: uuidv4(),
    color: "#ff0000",
    name: "GPT-3 Without Context",
    description: "Default GPT-3 project with no context",
    tags: ["tag1", "tag2"],
    settings: {
      model: "gpt-3.5-turbo",
      temperature: 1,
      max_length: 512,
      top_p: 1,
      frequency_penalty: 0,
      presence_penalty: 0,
    },
    context: [],
    creation_time: new Date().toISOString(),
    times_used: 0,
  },
  {
    id: uuidv4(),
    color: "#0000ff",
    name: "GPT-4 Turbo Without Context",
    description:
      "Default GPT-4 Turbo project with no context. Max 128k context.",
    tags: ["tag1", "tag2"],
    settings: {
      model: "gpt-4-1106-preview",
      temperature: 1,
      max_length: 512,
      top_p: 1,
      frequency_penalty: 0,
      presence_penalty: 0,
    },
    context: [],
    creation_time: new Date().toISOString(),
    times_used: 0,
  },
  {
    id: uuidv4(),
    color: "#008000",
    name: "GPT-3 Summarization",
    description: "Summarize all inputs",
    tags: ["tag1", "tag2"],
    settings: {
      model: "gpt-3.5-turbo",
      temperature: 1,
      max_length: 512,
      top_p: 1,
      frequency_penalty: 0,
      presence_penalty: 0,
    },
    context: [
      {
        role: "system",
        content: "Create summary",
      },
    ],
    creation_time: new Date().toISOString(),
    times_used: 0,
  },
];

export const DUMMY_PROJECTS: Project[] = [
  {
    id: uuidv4(),
    color: "red",
    name: "Attiq GPT Redux",
    description: "This is a dummy project with a veeeeery long description",
    tags: ["tag1", "tag2"],
    settings: {
      model: "gpt-4",
      temperature: 0.7,
      max_length: 500,
      top_p: 0.2,
      frequency_penalty: 0.2,
      presence_penalty: 0.3,
    },
    context: [
      {
        role: "system",
        content: "Answer questions and translate everything into Dutch",
      },
    ],
    creation_time: new Date().toISOString(),
    times_used: 10,
  },
  {
    id: uuidv4(),
    color: "blue",
    name: "Project Two",
    description: "This is a dummy project",
    tags: ["tag1", "tag2"],
    settings: {
      model: "gpt-3.5-turbo",
      temperature: 0.7,
      max_length: 500,
      top_p: 0.2,
      frequency_penalty: 0.2,
      presence_penalty: 0.3,
    },
    context: [
      { role: "user", content: "Hello, I am Giel" },
      { role: "assistant", content: "Hi there!" },
    ],
    creation_time: new Date().toISOString(),
    times_used: 10,
  },
  {
    id: uuidv4(),
    color: "red",
    name: "Not Giel",
    description: "This is a dummy project with a veeeeery long description",
    tags: ["tag1", "tag2"],
    settings: {
      model: "gpt-3.5-turbo",
      temperature: 0.7,
      max_length: 500,
      top_p: 0.2,
      frequency_penalty: 0.2,
      presence_penalty: 0.3,
    },
    context: [
      { role: "user", content: "Hello, I am not Giel" },
      { role: "assistant", content: "Hi there!" },
    ],
    creation_time: new Date().toISOString(),
    times_used: 10,
  },
  // ... add other 4 dummy projects following the same structure
  {
    id: uuidv4(),
    color: "blue",
    name: "Project Two",
    description: "This is a dummy project",
    tags: ["tag1", "tag2"],
    settings: {
      model: "gpt-3.5-turbo",
      temperature: 0.7,
      max_length: 500,
      top_p: 0.2,
      frequency_penalty: 0.2,
      presence_penalty: 0.3,
    },
    context: [
      { role: "user", content: "Hello, I am Giel" },
      { role: "assistant", content: "Hi there!" },
    ],
    creation_time: new Date().toISOString(),
    times_used: 10,
  },
  {
    id: uuidv4(),
    color: "red",
    name: "Attiq GPT Redux",
    description: "This is a dummy project with a veeeeery long description",
    tags: ["tag1", "tag2"],
    settings: {
      model: "gpt-3.5-turbo",
      temperature: 0.7,
      max_length: 500,
      top_p: 0.2,
      frequency_penalty: 0.2,
      presence_penalty: 0.3,
    },
    context: [
      { role: "user", content: "Hello, I am Giel" },
      { role: "assistant", content: "Hi there!" },
    ],
    creation_time: new Date().toISOString(),
    times_used: 10,
  },
  // ... add other 4 dummy projects following the same structure
  {
    id: uuidv4(),
    color: "blue",
    name: "Project Two",
    description: "This is a dummy project",
    tags: ["tag1", "tag2"],
    settings: {
      model: "gpt-3.5-turbo",
      temperature: 0.7,
      max_length: 500,
      top_p: 0.2,
      frequency_penalty: 0.2,
      presence_penalty: 0.3,
    },
    context: [
      { role: "user", content: "Hello, I am Giel" },
      { role: "assistant", content: "Hi there!" },
    ],
    creation_time: new Date().toISOString(),
    times_used: 10,
  },
  {
    id: uuidv4(),
    color: "red",
    name: "Attiq GPT Redux",
    description: "This is a dummy project with a veeeeery long description",
    tags: ["tag1", "tag2"],
    settings: {
      model: "gpt-3.5-turbo",
      temperature: 0.7,
      max_length: 500,
      top_p: 0.2,
      frequency_penalty: 0.2,
      presence_penalty: 0.3,
    },
    context: [
      { role: "user", content: "Hello, I am Giel" },
      { role: "assistant", content: "Hi there!" },
    ],
    creation_time: new Date().toISOString(),
    times_used: 10,
  },
  // ... add other 4 dummy projects following the same structure
  {
    id: uuidv4(),
    color: "blue",
    name: "Project Two",
    description: "This is a dummy project",
    tags: ["tag1", "tag2"],
    settings: {
      model: "gpt-3.5-turbo",
      temperature: 0.7,
      max_length: 500,
      top_p: 0.2,
      frequency_penalty: 0.2,
      presence_penalty: 0.3,
    },
    context: [
      { role: "user", content: "Hello, I am Giel" },
      { role: "assistant", content: "Hi there!" },
    ],
    creation_time: new Date().toISOString(),
    times_used: 10,
  },
  {
    id: uuidv4(),
    color: "red",
    name: "Attiq GPT Redux",
    description: "This is a dummy project with a veeeeery long description",
    tags: ["tag1", "tag2"],
    settings: {
      model: "gpt-4",
      temperature: 0.7,
      max_length: 500,
      top_p: 0.2,
      frequency_penalty: 0.2,
      presence_penalty: 0.3,
    },
    context: [
      {
        role: "system",
        content: "Answer questions and translate everything into Dutch",
      },
    ],
    creation_time: new Date().toISOString(),
    times_used: 10,
  },
  {
    id: uuidv4(),
    color: "blue",
    name: "Project Two",
    description: "This is a dummy project",
    tags: ["tag1", "tag2"],
    settings: {
      model: "gpt-3.5-turbo",
      temperature: 0.7,
      max_length: 500,
      top_p: 0.2,
      frequency_penalty: 0.2,
      presence_penalty: 0.3,
    },
    context: [
      { role: "user", content: "Hello, I am Giel" },
      { role: "assistant", content: "Hi there!" },
    ],
    creation_time: new Date().toISOString(),
    times_used: 10,
  },
  {
    id: uuidv4(),
    color: "red",
    name: "Not Giel",
    description: "This is a dummy project with a veeeeery long description",
    tags: ["tag1", "tag2"],
    settings: {
      model: "gpt-3.5-turbo",
      temperature: 0.7,
      max_length: 500,
      top_p: 0.2,
      frequency_penalty: 0.2,
      presence_penalty: 0.3,
    },
    context: [
      { role: "user", content: "Hello, I am not Giel" },
      { role: "assistant", content: "Hi there!" },
    ],
    creation_time: new Date().toISOString(),
    times_used: 10,
  },
  // ... add other 4 dummy projects following the same structure
  {
    id: uuidv4(),
    color: "blue",
    name: "Project Two",
    description: "This is a dummy project",
    tags: ["tag1", "tag2"],
    settings: {
      model: "gpt-3.5-turbo",
      temperature: 0.7,
      max_length: 500,
      top_p: 0.2,
      frequency_penalty: 0.2,
      presence_penalty: 0.3,
    },
    context: [
      { role: "user", content: "Hello, I am Giel" },
      { role: "assistant", content: "Hi there!" },
    ],
    creation_time: new Date().toISOString(),
    times_used: 10,
  },
  {
    id: uuidv4(),
    color: "red",
    name: "Attiq GPT Redux",
    description: "This is a dummy project with a veeeeery long description",
    tags: ["tag1", "tag2"],
    settings: {
      model: "gpt-3.5-turbo",
      temperature: 0.7,
      max_length: 500,
      top_p: 0.2,
      frequency_penalty: 0.2,
      presence_penalty: 0.3,
    },
    context: [
      { role: "user", content: "Hello, I am Giel" },
      { role: "assistant", content: "Hi there!" },
    ],
    creation_time: new Date().toISOString(),
    times_used: 10,
  },
  // ... add other 4 dummy projects following the same structure
  {
    id: uuidv4(),
    color: "blue",
    name: "Project Two",
    description: "This is a dummy project",
    tags: ["tag1", "tag2"],
    settings: {
      model: "gpt-3.5-turbo",
      temperature: 0.7,
      max_length: 500,
      top_p: 0.2,
      frequency_penalty: 0.2,
      presence_penalty: 0.3,
    },
    context: [
      { role: "user", content: "Hello, I am Giel" },
      { role: "assistant", content: "Hi there!" },
    ],
    creation_time: new Date().toISOString(),
    times_used: 10,
  },
  {
    id: uuidv4(),
    color: "red",
    name: "Attiq GPT Redux",
    description: "This is a dummy project with a veeeeery long description",
    tags: ["tag1", "tag2"],
    settings: {
      model: "gpt-3.5-turbo",
      temperature: 0.7,
      max_length: 500,
      top_p: 0.2,
      frequency_penalty: 0.2,
      presence_penalty: 0.3,
    },
    context: [
      { role: "user", content: "Hello, I am Giel" },
      { role: "assistant", content: "Hi there!" },
    ],
    creation_time: new Date().toISOString(),
    times_used: 10,
  },
  // ... add other 4 dummy projects following the same structure
  {
    id: uuidv4(),
    color: "blue",
    name: "Project Two",
    description: "This is a dummy project",
    tags: ["tag1", "tag2"],
    settings: {
      model: "gpt-3.5-turbo",
      temperature: 0.7,
      max_length: 500,
      top_p: 0.2,
      frequency_penalty: 0.2,
      presence_penalty: 0.3,
    },
    context: [
      { role: "user", content: "Hello, I am Giel" },
      { role: "assistant", content: "Hi there!" },
    ],
    creation_time: new Date().toISOString(),
    times_used: 10,
  },
  {
    id: uuidv4(),
    color: "red",
    name: "Attiq GPT Redux",
    description: "This is a dummy project with a veeeeery long description",
    tags: ["tag1", "tag2"],
    settings: {
      model: "gpt-4",
      temperature: 0.7,
      max_length: 500,
      top_p: 0.2,
      frequency_penalty: 0.2,
      presence_penalty: 0.3,
    },
    context: [
      {
        role: "system",
        content: "Answer questions and translate everything into Dutch",
      },
    ],
    creation_time: new Date().toISOString(),
    times_used: 10,
  },
  {
    id: uuidv4(),
    color: "blue",
    name: "Project Two",
    description: "This is a dummy project",
    tags: ["tag1", "tag2"],
    settings: {
      model: "gpt-3.5-turbo",
      temperature: 0.7,
      max_length: 500,
      top_p: 0.2,
      frequency_penalty: 0.2,
      presence_penalty: 0.3,
    },
    context: [
      { role: "user", content: "Hello, I am Giel" },
      { role: "assistant", content: "Hi there!" },
    ],
    creation_time: new Date().toISOString(),
    times_used: 10,
  },
  {
    id: uuidv4(),
    color: "red",
    name: "Not Giel",
    description: "This is a dummy project with a veeeeery long description",
    tags: ["tag1", "tag2"],
    settings: {
      model: "gpt-3.5-turbo",
      temperature: 0.7,
      max_length: 500,
      top_p: 0.2,
      frequency_penalty: 0.2,
      presence_penalty: 0.3,
    },
    context: [
      { role: "user", content: "Hello, I am not Giel" },
      { role: "assistant", content: "Hi there!" },
    ],
    creation_time: new Date().toISOString(),
    times_used: 10,
  },
  // ... add other 4 dummy projects following the same structure
  {
    id: uuidv4(),
    color: "blue",
    name: "Project Two",
    description: "This is a dummy project",
    tags: ["tag1", "tag2"],
    settings: {
      model: "gpt-3.5-turbo",
      temperature: 0.7,
      max_length: 500,
      top_p: 0.2,
      frequency_penalty: 0.2,
      presence_penalty: 0.3,
    },
    context: [
      { role: "user", content: "Hello, I am Giel" },
      { role: "assistant", content: "Hi there!" },
    ],
    creation_time: new Date().toISOString(),
    times_used: 10,
  },
  {
    id: uuidv4(),
    color: "red",
    name: "Attiq GPT Redux",
    description: "This is a dummy project with a veeeeery long description",
    tags: ["tag1", "tag2"],
    settings: {
      model: "gpt-3.5-turbo",
      temperature: 0.7,
      max_length: 500,
      top_p: 0.2,
      frequency_penalty: 0.2,
      presence_penalty: 0.3,
    },
    context: [
      { role: "user", content: "Hello, I am Giel" },
      { role: "assistant", content: "Hi there!" },
    ],
    creation_time: new Date().toISOString(),
    times_used: 10,
  },
  // ... add other 4 dummy projects following the same structure
  {
    id: uuidv4(),
    color: "blue",
    name: "Project Two",
    description: "This is a dummy project",
    tags: ["tag1", "tag2"],
    settings: {
      model: "gpt-3.5-turbo",
      temperature: 0.7,
      max_length: 500,
      top_p: 0.2,
      frequency_penalty: 0.2,
      presence_penalty: 0.3,
    },
    context: [
      { role: "user", content: "Hello, I am Giel" },
      { role: "assistant", content: "Hi there!" },
    ],
    creation_time: new Date().toISOString(),
    times_used: 10,
  },
  {
    id: uuidv4(),
    color: "red",
    name: "Attiq GPT Redux",
    description: "This is a dummy project with a veeeeery long description",
    tags: ["tag1", "tag2"],
    settings: {
      model: "gpt-3.5-turbo",
      temperature: 0.7,
      max_length: 500,
      top_p: 0.2,
      frequency_penalty: 0.2,
      presence_penalty: 0.3,
    },
    context: [
      { role: "user", content: "Hello, I am Giel" },
      { role: "assistant", content: "Hi there!" },
    ],
    creation_time: new Date().toISOString(),
    times_used: 10,
  },
  // ... add other 4 dummy projects following the same structure
  {
    id: uuidv4(),
    color: "blue",
    name: "Project Two",
    description: "This is a dummy project",
    tags: ["tag1", "tag2"],
    settings: {
      model: "gpt-3.5-turbo",
      temperature: 0.7,
      max_length: 500,
      top_p: 0.2,
      frequency_penalty: 0.2,
      presence_penalty: 0.3,
    },
    context: [
      { role: "user", content: "Hello, I am Giel" },
      { role: "assistant", content: "Hi there!" },
    ],
    creation_time: new Date().toISOString(),
    times_used: 10,
  },
  {
    id: uuidv4(),
    color: "red",
    name: "Attiq GPT Redux",
    description: "This is a dummy project with a veeeeery long description",
    tags: ["tag1", "tag2"],
    settings: {
      model: "gpt-4",
      temperature: 0.7,
      max_length: 500,
      top_p: 0.2,
      frequency_penalty: 0.2,
      presence_penalty: 0.3,
    },
    context: [
      {
        role: "system",
        content: "Answer questions and translate everything into Dutch",
      },
    ],
    creation_time: new Date().toISOString(),
    times_used: 10,
  },
  {
    id: uuidv4(),
    color: "blue",
    name: "Project Two",
    description: "This is a dummy project",
    tags: ["tag1", "tag2"],
    settings: {
      model: "gpt-3.5-turbo",
      temperature: 0.7,
      max_length: 500,
      top_p: 0.2,
      frequency_penalty: 0.2,
      presence_penalty: 0.3,
    },
    context: [
      { role: "user", content: "Hello, I am Giel" },
      { role: "assistant", content: "Hi there!" },
    ],
    creation_time: new Date().toISOString(),
    times_used: 10,
  },
  {
    id: uuidv4(),
    color: "red",
    name: "Not Giel",
    description: "This is a dummy project with a veeeeery long description",
    tags: ["tag1", "tag2"],
    settings: {
      model: "gpt-3.5-turbo",
      temperature: 0.7,
      max_length: 500,
      top_p: 0.2,
      frequency_penalty: 0.2,
      presence_penalty: 0.3,
    },
    context: [
      { role: "user", content: "Hello, I am not Giel" },
      { role: "assistant", content: "Hi there!" },
    ],
    creation_time: new Date().toISOString(),
    times_used: 10,
  },
  // ... add other 4 dummy projects following the same structure
  {
    id: uuidv4(),
    color: "blue",
    name: "Project Two",
    description: "This is a dummy project",
    tags: ["tag1", "tag2"],
    settings: {
      model: "gpt-3.5-turbo",
      temperature: 0.7,
      max_length: 500,
      top_p: 0.2,
      frequency_penalty: 0.2,
      presence_penalty: 0.3,
    },
    context: [
      { role: "user", content: "Hello, I am Giel" },
      { role: "assistant", content: "Hi there!" },
    ],
    creation_time: new Date().toISOString(),
    times_used: 10,
  },
  {
    id: uuidv4(),
    color: "red",
    name: "Attiq GPT Redux",
    description: "This is a dummy project with a veeeeery long description",
    tags: ["tag1", "tag2"],
    settings: {
      model: "gpt-3.5-turbo",
      temperature: 0.7,
      max_length: 500,
      top_p: 0.2,
      frequency_penalty: 0.2,
      presence_penalty: 0.3,
    },
    context: [
      { role: "user", content: "Hello, I am Giel" },
      { role: "assistant", content: "Hi there!" },
    ],
    creation_time: new Date().toISOString(),
    times_used: 10,
  },
  // ... add other 4 dummy projects following the same structure
  {
    id: uuidv4(),
    color: "blue",
    name: "Project Two",
    description: "This is a dummy project",
    tags: ["tag1", "tag2"],
    settings: {
      model: "gpt-3.5-turbo",
      temperature: 0.7,
      max_length: 500,
      top_p: 0.2,
      frequency_penalty: 0.2,
      presence_penalty: 0.3,
    },
    context: [
      { role: "user", content: "Hello, I am Giel" },
      { role: "assistant", content: "Hi there!" },
    ],
    creation_time: new Date().toISOString(),
    times_used: 10,
  },
  {
    id: uuidv4(),
    color: "red",
    name: "Attiq GPT Redux",
    description: "This is a dummy project with a veeeeery long description",
    tags: ["tag1", "tag2"],
    settings: {
      model: "gpt-3.5-turbo",
      temperature: 0.7,
      max_length: 500,
      top_p: 0.2,
      frequency_penalty: 0.2,
      presence_penalty: 0.3,
    },
    context: [
      { role: "user", content: "Hello, I am Giel" },
      { role: "assistant", content: "Hi there!" },
    ],
    creation_time: new Date().toISOString(),
    times_used: 10,
  },
  // ... add other 4 dummy projects following the same structure
  {
    id: uuidv4(),
    color: "blue",
    name: "Project Two",
    description: "This is a dummy project",
    tags: ["tag1", "tag2"],
    settings: {
      model: "gpt-3.5-turbo",
      temperature: 0.7,
      max_length: 500,
      top_p: 0.2,
      frequency_penalty: 0.2,
      presence_penalty: 0.3,
    },
    context: [
      { role: "user", content: "Hello, I am Giel" },
      { role: "assistant", content: "Hi there!" },
    ],
    creation_time: new Date().toISOString(),
    times_used: 10,
  },
  {
    id: uuidv4(),
    color: "red",
    name: "Attiq GPT Redux",
    description: "This is a dummy project with a veeeeery long description",
    tags: ["tag1", "tag2"],
    settings: {
      model: "gpt-4",
      temperature: 0.7,
      max_length: 500,
      top_p: 0.2,
      frequency_penalty: 0.2,
      presence_penalty: 0.3,
    },
    context: [
      {
        role: "system",
        content: "Answer questions and translate everything into Dutch",
      },
    ],
    creation_time: new Date().toISOString(),
    times_used: 10,
  },
  {
    id: uuidv4(),
    color: "blue",
    name: "Project Two",
    description: "This is a dummy project",
    tags: ["tag1", "tag2"],
    settings: {
      model: "gpt-3.5-turbo",
      temperature: 0.7,
      max_length: 500,
      top_p: 0.2,
      frequency_penalty: 0.2,
      presence_penalty: 0.3,
    },
    context: [
      { role: "user", content: "Hello, I am Giel" },
      { role: "assistant", content: "Hi there!" },
    ],
    creation_time: new Date().toISOString(),
    times_used: 10,
  },
  {
    id: uuidv4(),
    color: "red",
    name: "Not Giel",
    description: "This is a dummy project with a veeeeery long description",
    tags: ["tag1", "tag2"],
    settings: {
      model: "gpt-3.5-turbo",
      temperature: 0.7,
      max_length: 500,
      top_p: 0.2,
      frequency_penalty: 0.2,
      presence_penalty: 0.3,
    },
    context: [
      { role: "user", content: "Hello, I am not Giel" },
      { role: "assistant", content: "Hi there!" },
    ],
    creation_time: new Date().toISOString(),
    times_used: 10,
  },
  // ... add other 4 dummy projects following the same structure
  {
    id: uuidv4(),
    color: "blue",
    name: "Project Two",
    description: "This is a dummy project",
    tags: ["tag1", "tag2"],
    settings: {
      model: "gpt-3.5-turbo",
      temperature: 0.7,
      max_length: 500,
      top_p: 0.2,
      frequency_penalty: 0.2,
      presence_penalty: 0.3,
    },
    context: [
      { role: "user", content: "Hello, I am Giel" },
      { role: "assistant", content: "Hi there!" },
    ],
    creation_time: new Date().toISOString(),
    times_used: 10,
  },
  {
    id: uuidv4(),
    color: "red",
    name: "Attiq GPT Redux",
    description: "This is a dummy project with a veeeeery long description",
    tags: ["tag1", "tag2"],
    settings: {
      model: "gpt-3.5-turbo",
      temperature: 0.7,
      max_length: 500,
      top_p: 0.2,
      frequency_penalty: 0.2,
      presence_penalty: 0.3,
    },
    context: [
      { role: "user", content: "Hello, I am Giel" },
      { role: "assistant", content: "Hi there!" },
    ],
    creation_time: new Date().toISOString(),
    times_used: 10,
  },
  // ... add other 4 dummy projects following the same structure
  {
    id: uuidv4(),
    color: "blue",
    name: "Project Two",
    description: "This is a dummy project",
    tags: ["tag1", "tag2"],
    settings: {
      model: "gpt-3.5-turbo",
      temperature: 0.7,
      max_length: 500,
      top_p: 0.2,
      frequency_penalty: 0.2,
      presence_penalty: 0.3,
    },
    context: [
      { role: "user", content: "Hello, I am Giel" },
      { role: "assistant", content: "Hi there!" },
    ],
    creation_time: new Date().toISOString(),
    times_used: 10,
  },
  {
    id: uuidv4(),
    color: "red",
    name: "Attiq GPT Redux",
    description: "This is a dummy project with a veeeeery long description",
    tags: ["tag1", "tag2"],
    settings: {
      model: "gpt-3.5-turbo",
      temperature: 0.7,
      max_length: 500,
      top_p: 0.2,
      frequency_penalty: 0.2,
      presence_penalty: 0.3,
    },
    context: [
      { role: "user", content: "Hello, I am Giel" },
      { role: "assistant", content: "Hi there!" },
    ],
    creation_time: new Date().toISOString(),
    times_used: 10,
  },
  // ... add other 4 dummy projects following the same structure
  {
    id: uuidv4(),
    color: "blue",
    name: "Project Two",
    description: "This is a dummy project",
    tags: ["tag1", "tag2"],
    settings: {
      model: "gpt-3.5-turbo",
      temperature: 0.7,
      max_length: 500,
      top_p: 0.2,
      frequency_penalty: 0.2,
      presence_penalty: 0.3,
    },
    context: [
      { role: "user", content: "Hello, I am Giel" },
      { role: "assistant", content: "Hi there!" },
    ],
    creation_time: new Date().toISOString(),
    times_used: 10,
  },
];
