import React from "react";
import { Typography, Row, Col, Select, InputNumber, Form } from "antd";
import { AnimatePresence, motion } from "framer-motion";
import { Model, GPT35, GPT4 } from "../../types/chatTypes";

const { Title, Text } = Typography;
const { Option } = Select;

const modelTypes: Model[] = [
  "gpt-4o",
  "gpt-4-1106-preview",
  "gpt-4-vision-preview",
  "gpt-4",
  "gpt-4-32k",
  "gpt-4-0613",
  "gpt-4-32k-0613",
  "gpt-3.5-turbo",
  "gpt-3.5-turbo-0613",
  "gpt-3.5-turbo-16k",
  "gpt-3.5-turbo-16k-0613",
];

function ProjectSettings() {
  // Form is actually used as reference from parent component, do not remove
  const form = Form.useFormInstance();

  return (
    <AnimatePresence>
      <motion.div
        initial={{ x: 1000, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        exit={{ x: -1000, opacity: 0, transition: { duration: 0.3 } }}
      >
        {/* <Form name="project-settings" layout="vertical"> */}
        <Row gutter={24}>
          <Col span={12}>
            <Title level={2}>Project Settings</Title>
            <Text>
              Configure the project settings, such as model, temperature,
              maximum length, top P, frequency penalty, and presence penalty.
            </Text>
          </Col>
          <Col span={12}>
            <Form.Item name="model" label="Model">
              <Select placeholder="Select Model">
                {modelTypes.map((model) => (
                  <Option value={model} key={model}>
                    {model}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="temperature" label="Temperature">
              <InputNumber min={0} max={1} step={0.01} />
            </Form.Item>
            <Form.Item name="max_length" label="Maximum length">
              <InputNumber min={1} max={2048} />
            </Form.Item>
            <Form.Item name="top_p" label="Top P">
              <InputNumber min={0} max={1} step={0.01} />
            </Form.Item>
            <Form.Item name="frequency_penalty" label="Frequency penalty">
              <InputNumber min={0} max={2} step={0.01} />
            </Form.Item>
            <Form.Item name="presence_penalty" label="Presence penalty">
              <InputNumber min={0} max={2} step={0.01} />
            </Form.Item>
          </Col>
        </Row>
        {/* </Form> */}
      </motion.div>
    </AnimatePresence>
  );
}

export default ProjectSettings;
