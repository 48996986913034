import React, { useEffect, useState } from "react";
import { useOpenAIChatStream } from "../../hooks/useOpenAIChatStream";
import { Button, Input, Layout, Row, Col, Typography, theme } from "antd";
import { MinusCircleOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";

const { useToken } = theme;

const { TextArea } = Input;
const { Text } = Typography;

function Chat() {
  const [inputMessage, setInputMessage] = useState("");
  const sidebarCollapsed = useSelector(
    (state: any) => state.app.sidebar_collapsed
  );
  const apiKey = useSelector((state: any) => state.app.api_key);
  const sendChatOnEnter = useSelector(
    (state: any) => state.app.send_chat_on_enter
  );
  const selectedProject = useSelector(
    (state: any) => state.selectedProject.project
  );
  const defaultModel = useSelector((state: any) => state.app.default_model);
  const currentModel = selectedProject?.settings?.model ?? defaultModel;
  const context = selectedProject?.context ?? [];
  const endOfMessagesRef = React.useRef<null | HTMLDivElement>(null);

  const {
    messages,
    submitPrompt,
    resetMessages,
    isLoading,
    abortStream,
    updateMessage,
    deleteMessage,
    error,
  } = useOpenAIChatStream({
    apiKey: apiKey,
    model: currentModel,
    context: context ?? [],
  });
  const { token } = useToken();

  const handleSendMessage = async () => {
    if (!inputMessage) return;
    setInputMessage("");
    await submitPrompt([{ role: "user", content: inputMessage }]);
  };

  const handleInputMessageChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setInputMessage(event.target.value);
  };

  const handlePreviousMessageChange = (
    index: number,
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    updateMessage(index, event.target.value);
  };

  // update handleKeyPress so it does not send on shift+enter, it sends on enter if sendChatOnEnter is true, otherwise it sends on ctrl+enter
  const handleKeyPress = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === "Enter") {
      if (event.ctrlKey) {
        handleSendMessage();
        event.preventDefault(); // prevent the newline character from being added to the inputMessage
      } else if (!event.shiftKey && sendChatOnEnter) {
        handleSendMessage();
        event.preventDefault(); // prevent the newline character from being added to the inputMessage
      }
    }
  };

  const scrollToBottom = () => {
    endOfMessagesRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  console.log("messages", messages);
  console.log("context", context);

  return (
    <Layout
      style={{
        height: `calc(100vh - 220px)`,
        overflowY: "scroll",
      }}
    >
      {error && (
        <Row style={{ padding: "4px" }}>
          <Col span={24}>
            <Text type="danger">{error}</Text>
          </Col>
        </Row>
      )}
      <Layout.Content>
        <div>
          {messages.map((message, index) => {
            return (
              <Row key={index} style={{ padding: "4px" }}>
                <Col span={2}>
                  <Text type="secondary">{message.role}</Text>
                </Col>
                <Col span={20}>
                  <TextArea
                    autoSize
                    value={message.content}
                    onChange={(event) =>
                      handlePreviousMessageChange(index, event)
                    }
                  />
                </Col>
                <Col span={2} style={{ padding: "4px" }}>
                  <MinusCircleOutlined
                    style={{
                      fontSize: "24px",
                      color: token.colorTextSecondary,
                    }}
                    onClick={() => deleteMessage(index)}
                  />
                </Col>
              </Row>
            );
          })}
          <div ref={endOfMessagesRef} />
        </div>
      </Layout.Content>

      <Layout
        style={{
          position: "fixed",
          bottom: 0,
          width: sidebarCollapsed
            ? `calc(100vw - 104px)`
            : `calc(100vw - 224px)`,
          minHeight: 70,
          maxHeight: "50%",
          padding: "8px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-end",
          flexDirection: "column",
        }}
      >
        <TextArea
          value={inputMessage}
          onChange={handleInputMessageChange}
          onKeyDown={handleKeyPress} // add this line
          placeholder="Type your message here"
          style={{ flexGrow: 1, resize: "none" }}
          autoSize={{ minRows: 1, maxRows: 10 }}
        />
        <Row
          style={{
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Col>
            <Text type="secondary">{currentModel}</Text>
          </Col>
          <Col>
            <Button onClick={resetMessages}>Reset</Button>
            <Button onClick={abortStream} disabled={!isLoading}>
              Stop
            </Button>
            <Button onClick={handleSendMessage} disabled={isLoading}>
              Send
            </Button>
          </Col>
        </Row>
      </Layout>
    </Layout>
  );
}

export default Chat;
