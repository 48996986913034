import { Fragment } from "react";
import PageHeader from "../components/PageHeader";
import AnimatedText from "../components/AnimatedText";
import { Layout, Timeline } from "antd";

const items = [
  {
    label: "2023-09-01 09:12:11",
    children: "Create a services",
  },
  {
    label: "2023-09- 09:12:11",
    children: "Solve initial network problems",
  },
  {
    label: "2023-09- 09:12:11",
    children: "Technical testing",
  },
  {
    label: "2023-09-01 09:12:11",
    children: "Network problems being solved",
    onClick: () => {
      console.log("change");
    },
  },
];

function HistoryPage() {
  return (
    <Fragment>
      <PageHeader title="history"></PageHeader>
      <Layout
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <AnimatedText text="Coming Soon" type="title"></AnimatedText>
      </Layout>
    </Fragment>
  );
}

export default HistoryPage;
