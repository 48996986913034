import React, { Dispatch, SetStateAction } from "react";
import { Steps } from "antd";

interface ProjectStepsProps {
  currentStep: number;
  setStep: Dispatch<SetStateAction<number>>;
}

function ProjectSteps({ currentStep, setStep }: ProjectStepsProps) {
  const onChange = (step: number) => {
    setStep(step);
  };

  return (
    <Steps
      style={{
        margin: "16px 0",
      }}
      current={currentStep}
      onChange={onChange}
      items={[
        {
          title: "Identity",
        },
        {
          title: "Settings",
        },
        {
          title: "Context",
        },
      ]}
    />
  );
}

export default ProjectSteps;
