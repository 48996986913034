import React, { Fragment } from "react";
import { Layout, Typography, Button } from "antd";
import { LinkedinOutlined } from "@ant-design/icons";
import PageHeader from "../components/PageHeader";

const { Title, Paragraph } = Typography;

function InfoPage() {
  return (
    <Fragment>
      <PageHeader title="Info" />
      <Layout
        style={{
          display: "flex",
          flexDirection: "column",
          // justifyContent: "center",
          alignItems: "center",
          padding: "2rem",
          // height: `calc(100vh - 220px)`,
        }}
      >
        <Title level={2}>Accelerai Description</Title>
        <Paragraph style={{ maxWidth: "80%", textAlign: "center" }}>
          AccelerAI is about creating projects that contain context. This
          context can be utilized to engage with Large Language Models (LLMs)
          through a streaming chat functionality. The interactive nature of this
          application allows for dynamic communication with AI, paving the way
          for novel experiences.
        </Paragraph>
        <Button
          type="link"
          icon={<LinkedinOutlined />}
          size="large"
          href="https://www.linkedin.com/in/giel-oomen/"
          target="_blank"
        >
          Connect with me on Linkedin
        </Button>
      </Layout>
    </Fragment>
  );
}

export default InfoPage;
