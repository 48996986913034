import React from "react";
import { Typography, Row, Col, Input, Select, ColorPicker, Form } from "antd";
import { AnimatePresence, motion } from "framer-motion";
import { useSelector } from "react-redux";
import { Project } from "../../store/types";
import { RootState } from "../../store";

const { Title, Text } = Typography;
const { TextArea } = Input;

function ProjectIdentity() {
  // Form is actually used as reference from parent component, do not remove
  const form = Form.useFormInstance();

  const recentColors = useSelector((state: RootState) => {
    const colors = Object.values(state.projects.projects)
      .filter((project: Project | null): project is Project => project !== null)
      .map((project: Project) => project.color);

    // Remove duplicates
    const uniqueColors = Array.from(new Set(colors));
    console.log(uniqueColors);

    return [{ label: "Recent Colors", colors: uniqueColors }];
  });

  return (
    <AnimatePresence>
      <motion.div
        initial={{ x: 1000, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        exit={{ x: -1000, opacity: 0, transition: { duration: 0.3 } }}
      >
        <Row gutter={24}>
          <Col span={12}>
            <Title level={2}>Project Identity</Title>
            <Text>
              Set the project name, tags, and color to help with organization
              and identification.
            </Text>
          </Col>
          <Col span={12}>
            <Form.Item name="name" label="Name">
              <Input placeholder="Project Name" />
            </Form.Item>
            <Form.Item name="description" label="Description">
              <TextArea autoSize placeholder="Project Description" />
            </Form.Item>
            <Form.Item name="tags" label="Tags">
              <Select
                mode="tags"
                style={{ width: "100%" }}
                placeholder="Select Tags"
              ></Select>
            </Form.Item>
            <Form.Item name="color" label="Color">
              <ColorPicker format="hex" presets={recentColors} />
            </Form.Item>
          </Col>
        </Row>
        {/* </Form> */}
      </motion.div>
    </AnimatePresence>
  );
}

export default ProjectIdentity;
